<template>
  <div class="content-block">
    <div class="content-header">
      Создание проверки
      <div class="content-manage">
        <!-- <div class="checkbox-container">
          <checkbox
            label="Все со статусом «Не соответствует»"
            :start-active="showNotApproved"
            @change-handler="showNotApprovedToggle"
          />
        </div> -->
        <div class="select-header">Выбрано: {{ requirements.length }}</div>
        <div class="next-button">
          <nextButton v-if="checkSave" text="Создать проверку" :disabled="isChecklistCreateLoading" @click-handler="createCheckList" />
          <nextButton v-else text="СОЗДАТЬ ЧЕК-ЛИСТ" class="inactive" />
        </div>
        <nextButton color="white" text="Отменить" @click-handler="goBack" />
      </div>
    </div>
    <div class="main-block">
      <div class="content-block">
        <div class="content-header">Раздел:</div>
        <div class="content-info">
          <dropdownListWithHeader
            placeholder="Выберите чек-лист"
            :list="organizationProcess"
            :disabled="selectedProcesses.length === 1"
            :search="false"
            @input-handler="searchOrganizationProcess"
            @enter-handler="searchOrganizationProcessEnter"
            @select-handler="organizationProcessSelect"
          />
        </div>
        <div class="content-list">
          <div v-for="(item, key) in selectedProcesses" :key="key" class="med-item">
            <div class="med-item__text" :title="item.text">
              {{ item.text }}
            </div>
            <span class="icon red-christ-icon pointer" @click="removeOrganizationProcess(item)" />
          </div>
        </div>
      </div>
      <div class="extra-settings">
        <div class="settings-item">
          <p class="mb-2 text-sm">Аудитор</p>
          <NSelect
            v-if="auditors.length > 0" 
            style="width: 300px"
            filterable
            size="large"
            :options="auditors" 
            :on-update:value="updateAuditor" 
            :value="auditor.id"
          />
        </div>
        <div class="settings-item">
          <dropdownListWithHeader
            v-if="auditionType !== ''"
            :start-item="auditionType"
            header="Вид проверки"
            :required="true"
            :search="false"
            :list="auditionTypes"
            @select-handler="changeAuditionType"
          />
        </div>
        <div class="settings-item settings__date-picker">
          <span>Плановая дата начала <strong>*</strong></span>
          <NDatePicker v-model:value="plan_date" format="dd.MM.yyyy" aria-required type="date" size="large" />
        </div>

        <div class="settings-item">
          <inputWithHeader
            header="Подразделение/Отдел"
            @input-handler="changeSubdivision"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkbox from '@/components/widgets/simple-components/inputs/checkbox'
import dropdownListWithHeader from '@/components/widgets/simple-components/inputs/dropdown/dropdownListWithHeader'
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'
import inputWithHeader from '@/components/widgets/simple-components/inputs/inputWithHeader'
import { NDatePicker, NSelect } from 'naive-ui'

export default {
  name: 'CreateCheckListPage',
  components: {
    checkbox,
    dropdownListWithHeader,
    nextButton,
    inputWithHeader,
    NDatePicker,
    NSelect
  },
  data() {
    return {
      isChecklistCreateLoading: false,
      processText: '',
      auditors: [],
      auditionTypes: [],
      auditionType: '',
      auditor: '',
      subdivision: '',
      plan_date: null,
      selectedProcesses: [],
      showNotApproved: false,
      medType: Number.parseInt(this.$router.currentRoute.value.query?.medtype) || 0
    }
  },
  computed: {
    requirements() {
      return this.$store.getters['requirements']
    },
    selectedProcessesId() {
      return this.selectedProcesses.map((item) => item.id)
    },
    organizationProcessesId() {
      let result = []
      for (let i = 0; i < this.$store.getters['organizationProcesses'].length; i++) {
        let flag = false
        for (let n = 0; n < this.selectedProcesses.length; n++) {
          if (this.selectedProcesses[n].id === this.$store.getters['organizationProcesses'][i].process.id) {
            flag = true
          }
        }
        if (!flag) {
          result.push(this.$store.getters['organizationProcesses'][i].process.id)
        }
      }
      return result
    },
    organizationProcess() {
      let result = []
      for (let i = 0; i < this.$store.getters['organizationProcesses'].length; i++) {
        let flag = false
        for (let n = 0; n < this.selectedProcesses.length; n++) {
          if (this.selectedProcesses[n].id === this.$store.getters['organizationProcesses'][i].process.id) {
            flag = true
          }
        }
        if (!flag) {
          result.push(this.$store.getters['organizationProcesses'][i].process)
        }
      }
      result.sort((a, b) => a.number - b.number)
      return result
    },
    checkSave() {
      if (this.requirements.length === 0 || this.selectedProcesses.length === 0) {
        return false
      }
      if (this.auditionType === '') {
        return false
      }
      return true
    }
  },
  async mounted() {
    this.setDefaultPlanDate()

    await this.$store.dispatch('ORGANIZATION_UPDATE_ORGANIZATION_PROCESSES_BY_MED_ID', this.medType)
    this.$api.get('responsible/get').then((res) => {
      if (res.data.ok === 1) {
        if (res.data.data.length > 0) {
          this.auditor = {
            text: res.data.data[0].name,
            id: res.data.data[0].id
          }
        }
        for (let i = 0; i < res.data.data.length; i++) {
          this.auditors.push({
            text: res.data.data[i].name,
            label: res.data.data[i].name,
            id: res.data.data[i].id,
            value: res.data.data[i].id
          })
        }
        
        this.auditors.sort((a, b) => a.label.localeCompare(b.label))
      }
    })
    // this.$store.dispatch('REQUIREMENT_TAKE_BY_MEDTYPE', this.medType)
    this.$store.dispatch('CHECKLIST_GET_AUDITION_TYPES').then((res) => {
      if (res.data.ok === 1) {
        this.auditionTypes = res.data.data
        this.auditionType = res.data.data[0]
      }
    })
  },
  async unmounted() {
    this.$store.dispatch('REQUIREMENT_CLEAR')
  },
  methods: {
    setDefaultPlanDate() {
      this.plan_date = new Date().getTime() 
    },
    changeAuditionType(val) {
      this.auditionType = val
    },
    changeSubdivision(val) {
      this.subdivision = val
    },
    goBack() {
      this.$router.back()
    },
    async createCheckList() {
      if (!this.plan_date) {
        this.$store.dispatch('ALERT_CREATE', { text: 'Заполните поле "Плановая дата начала"', type: 'warning' })
      } else {
        this.isChecklistCreateLoading = true

        let res = await this.$store.dispatch('CHECKLIST_CREATE', {
          plan_date: this.plan_date / 1000,
          assurance_user_id: this.auditor.id,
          assurance_id: this.auditionType.id,
          requirements: this.$store.getters['requirements'].map((item) => item.id),
          subdivision: this.subdivision,
        })
        if (res.data.ok === 1) {
          this.$router.push('/checklist/' + res.data.data.number)
        } else {
          this.$store.dispatch('ALERT_CREATE', { text: res.data.message, type: 'warning' })
        }
        this.isChecklistCreateLoading = false
      }
    },
    removeOrganizationProcess(item) {
      this.selectedProcesses = this.selectedProcesses.filter((process) => process !== item)

      if (this.selectedProcesses.length) {
        this.$store.dispatch('REQUIREMENT_TAKE_WITH_FILTERS', { processes: this.selectedProcessesId })
      }
    },
    searchOrganizationProcessEnter(val) {
      this.processText = val
    },
    searchOrganizationProcess(val) {
      this.processText = val
    },
    organizationProcessSelect(val) {
      this.selectedProcesses.push(val)
      this.$store.dispatch('REQUIREMENT_TAKE_WITH_FILTERS', { processes: this.selectedProcessesId })
    },
    async getMedTypes() {
      this.$store.dispatch('ORGANIZATION_UPDATE_ORGANIZATION_MEDTYPES')
    },
    showNotApprovedToggle(val) {
      this.showNotApproved = val
      if (val) {
        this.$store.dispatch('REQUIREMENT_REFRESH_DONT_MATCHES', this.medType)
      } else {
        this.$store.dispatch('REQUIREMENT_TAKE_WITH_FILTERS', { processes: this.selectedProcessesId })
      }
    },
    updateAuditor(value, option) {
      this.auditor = option
    }
  }
}
</script>

<style scoped lang="scss">
.settings-item {
  min-width: 200px;
  padding-right: 20px;
}
.extra-settings {
  display: flex;
}
.settings__date-picker span {
  font-size: 14px;
  margin-bottom: 7px;
  display: block;

  strong {
    color: #1CC8AB
  }
}
.input-block {
  width: 100%;
}
.content-list {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.content-info {
  position: relative;
  z-index: 2;
}
.content-manage {
  display: flex;
}
.red-christ-icon {
  position: absolute;
  right: 4px;
  top: 7px;
}
.med-item__text {
  white-space: nowrap; /* Текст не переносится */
  overflow: hidden; /* Обрезаем всё за пределами блока */
  text-overflow: ellipsis;
  max-width: 300px;
  padding-right: 10px;
}
.med-item {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f2f2f2;
  max-width: 300px;
  position: relative;
  box-shadow: 1px 1px 0px #cccccc;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}
.select-header {
  padding-left: 30px;
  padding-right: 60px;
}
.next-button {
  margin-right: 30px;
}
.main-block {
  background-color: white;
  padding: 23px 40px;
}
</style>

<!-- АДАПТИВ -->
<style scoped lang="scss">
@media (max-width: 1200px) {
  .content-header {
    flex-direction: column;
    gap: 25px;
    font-size: 24px;
  }

  .content-manage {
    flex-flow: row wrap;
    gap: 15px;
  }

  .checkbox-container {
    min-width: 240px;
    width: calc(50% - 15px);
    margin-bottom: 15px;
  }

  .select-header {
    width: calc(50% - 15px);
    margin-bottom: 15px;
    padding: 0;
  }
}

@media (max-width: 800px) {
  .main-block {
    padding: 20px 15px;
  }

  .next-button {
    margin-right: 0;
  }

  .extra-settings {
    flex-direction: column;
    padding-top: 20px;
  }

  .settings-item {
    padding: 0;
    margin-bottom: 25px;
  }

  .med-item {
    padding-top: 8px;
    padding-bottom: 8px;
    margin-right: 0;
  }

  .med-item__text {
    white-space: normal;
  }
}
</style>