<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import MainTab from '@/components/pages/organization/MainTab'
import DocumentsTabs from '@/components/pages/organization/DocumentsTab'
import ProcessesTab from '@/components/pages/organization/ProcessesTab'
import VkkTab from '@/components/pages/organization/VkkTab'
import UsersTab from '@/components/pages/organization/UsersTab'
import Tariffs from '@/components/pages/organization/Tariffs'
import DepartmentsTab from '@/components/pages/organization/DepartmentsTab'
import { useOrganizationStore } from '@/store/organization'
import useTarifExtension from '@/composables/useTarifExtension'

const organizationStore = useOrganizationStore()
const store = useStore()
const router = useRouter()

const organizationId = computed(() => organizationStore.organization?.id)
const hasOrganization = computed(() => !!organizationId.value)
const { hasPermission } = useTarifExtension();

const activeTab = ref('main')
const tabs = ref([
  { key: 'main', title: 'Общая информация', },
  { key: 'documents', title: 'Документы', },
  
])

// 1 Супер админ
// 2 Администратор
// 3 Пользователь
const userIsSuperAdmin = [1].includes(JSON.parse(localStorage.user).role_id)
const userIsAdmin = [1, 2].includes(JSON.parse(localStorage.user).role_id)

const isBaseTarif = JSON.parse(localStorage.user).organization.tarif_id === 1

if (userIsAdmin) {
  tabs.value = [
    { key: 'main', title: 'Общая информация', },
    { key: 'process', title: 'Направления', },
    { key: 'vkk', title: 'Показатели', },
    { key: 'documents', title: 'Документы', },
    { key: 'users', title: 'Ответственные', },
    // { key: 'tariffs', title: 'Тарифы', }
  ]
}

if (userIsSuperAdmin) {
  tabs.value = [
    { key: 'main', title: 'Общая информация', },
    { key: 'departments', title: 'Подразделения', },
    { key: 'process', title: 'Направления', },
    { key: 'vkk', title: 'Показатели', },
    { key: 'documents', title: 'Документы', },
    { key: 'users', title: 'Ответственные', },
    // { key: 'tariffs', title: 'Тарифы', },
  ]
}

if (isBaseTarif) {
  tabs.value.push({ key: 'tariffs', title: 'Тарифы', })
}

// убрать таб тарифов для DEV
// if (location.host.includes('dev-front')) {
//   tabs.value = tabs.value.filter(tab => tab.key !== 'tariffs')
// }

onMounted(() => {
  const currentUrlTab = router.currentRoute.value.params.tab

  if (currentUrlTab !== 'main') {
    setTab(currentUrlTab)
  }
})

function setTab(tabKey) {
  if (tabKey === 'main') {
    store.dispatch('ORGANIZATION_GET_ALL_MEDHELPS')
  }
  
  if (hasOrganization.value) {
    activeTab.value = tabKey
    router.push('/organization/' + tabKey)
  }
}
</script>

<template>
  <div>
    <h1 class="page-header">Организация</h1>
    <div class="tabs">
      <div
        v-for="tab in tabs"
        :key="tab.key"
        class="tab"
        :class="{ active: activeTab === tab.key, disabled: activeTab !== tab.key && !hasOrganization }"
        @click="setTab(tab.key)"
        >
        {{ tab.title }}
      </div>
    </div>
    <div class="tab-content">
      <MainTab v-if="activeTab === 'main'" :has-organization="hasOrganization" :has-permission="hasPermission(1)" />
      <DepartmentsTab v-if="activeTab === 'departments'" />
      <DocumentsTabs v-if="activeTab === 'documents'" :has-permission="hasPermission(5)" />
      <ProcessesTab v-if="userIsAdmin && activeTab === 'process'" :has-permission="hasPermission(2)" />
      <VkkTab v-if="userIsAdmin && activeTab === 'vkk'" :has-permission="hasPermission(4)" />
      <UsersTab v-if="userIsAdmin && activeTab === 'users'" :has-permission="hasPermission(6)" />
      <Tariffs v-if="isBaseTarif && activeTab === 'tariffs'" />
    </div>
  </div>
</template>
